/* add css module styles here (optional) */
@import url("https://fonts.googleapis.com/css?family=Lato:400,700&display=swap");
._1ceqH,._1ceqH *{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif
}
._1ceqH ._2fG9h{
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
}
._1ceqH ._dt3-T {
  min-height: 400px;
  background: #212121;
  border-radius: 0 0 3px 3px;
  display: flex;
  flex-direction: column;
}
._1ceqH  ._1dpop{
  width: 60px;
  display: flex;
  height: 60px;
  position: fixed;
  justify-content: center;
  align-items: center;
  background: rgb(245, 0, 87);
  border-radius: 50%;
  bottom: 65px;
  right: 20%;
  color: #fff;
  font-size: 25px;
}
._1ceqH ._1lB9c{
    display: flex;
    justify-content: space-between;
    background: #bd9f61;
    align-items: center;
    padding: 20px 20px;
    color: #fff;
    border-radius: 3px 3px 0 0;
}
._1ceqH ._1lB9c ._2N9dq {
  font-weight: 400;
}
._1ceqH ._1lB9c ._3-aC9 {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease all;
}
._1ceqH ._1lB9c ._3-aC9:hover {
  background: rgba(123, 118, 106, 0.21);
}

._1ceqH ._3bC73 {
  position: relative;
  flex: 1;
}
._1ceqH ._3bC73 ._1dpop {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}
._1ceqH ._3bC73 ._1YOWG {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 100px;
}

._1ceqH ._1Yplu{
  margin: 20px 30px;
}
._1ceqH ._1Yplu ._1Pz2d{
  border: 0;
  outline: 0;
  padding: 10px 20px;
  border-radius: 20px;
  background: #185fec;
  color: #fff;
  cursor: pointer;
  border:1px solid #185fec;
  transition:.3s ease all;
}
._1ceqH ._1Yplu ._1Pz2d:hover{
  background: #fff;
  color: #185fec;
}
._1ceqH ._1Yplu ._2gd2_{
  background: #fff;
  color: #185fec;
  margin-left:15px;
}
._1ceqH ._1Yplu ._2gd2_:hover{
  background: #185fec;
  color: #fff;
}
._1ceqH ._f2DT8{
  text-align:center;
}
._1ceqH ._17RTH {
  min-height: calc(100vh - 128px);
  background: #fff;
}

._1ceqH ._f2DT8 * {
  color: #fff;
  font-size: 60px;
}
._1ceqH ._1YOWG ._eV_dK {
  color: #fff;
}

._1ceqH ._qxztz {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0px;
  padding: 20px 0;
  display: flex;
}

._1ceqH ._qxztz ._2uz65 {
  width: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  margin-right: 15px;
  font-size: 20px;
}

._1ceqH ._qxztz ._1bSom {
  background: #940505;
}
._1ceqH ._qxztz ._3nQu5 {
  background: #9c6702;
}